import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link, useLocation } from 'react-router-dom';

import { getTranslator } from '../../../../app/modules/Util/Common';

const getBreadcrumb = (location) => {
  let breadcrumb = [];
  const items = location.pathname.split("/").filter(Boolean);
  items.map((item, index) => {
    const data = lists.find((ele) => ele.path === item);
    if (data) {
      breadcrumb.push(<span key={index}>{data.tag}</span>);
    } else {
      breadcrumb.push(<span key={index}>{item}</span>);
    }
  })
  return breadcrumb;
}

export default function Breadcrumb() {
  const location = useLocation();
  return (
    <div className="container-fluid mb-2">
      <div className="d-flex flex-row">
        <div className="flex-row-fluid">
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/">{getTranslator("HOME")}</Link>
            {/* {getCurrentBreadcrumb(location)} */}
            {getBreadcrumb(location)}
          </Breadcrumbs>
        </div>
      </div>
    </div>
  );
}




const lists = [
  {
    path: "transaction",
    tag: <Link to="/transaction">{getTranslator("TRANSACTION")}</Link>
  },
  {
    path: "user",
    tag: [<Link to="/user/show-user">{getTranslator("USER")}</Link>]
  },
  {
    path: "show-user",
    tag: [<Link to="/user/show-user">{getTranslator("USER_LIST")}</Link>]
  },
  {
    path: "show-external-user",
    tag: [<Link to="/user/show-external-user">{getTranslator("USER_LIST")}</Link>]
  },
  {
    path: "create-user",
    tag: [<Link to="/user/create-user">{getTranslator("CREATE_USER")}</Link>]
  },
  {
    path: "modify-user",
    tag: [<Link to="/user/modify-user">{getTranslator("MODIFY_USER")}</Link>]
  },
  {
    path: "modify-password",
    tag: [<Link to="/user/modify-password">{getTranslator("MODIFY_PASSWORD")}</Link>]
  },
  {
    path: "profile",
    tag: [<Link to="/profile">{getTranslator("PROFILE")}</Link>]
  },

  {
    path: "account-information",
    tag: [<Link to="/profile">{getTranslator("ACCOUNT_INFORMATION")}</Link>]
  },

  {
    path: "change-password",
    tag: [<Link to="/profile/change-password">{getTranslator("CHANGE_PASSWORD")}</Link>]
  },
  {
    path: "mid-management",
    tag: [<Link to="/mid-management">{getTranslator("MID")}</Link>]
  },
  {
    path: "merchant",
    tag: [<Link to="/mid-management/merchant">{getTranslator("MERCHANT")}</Link>]
  },
  {
    path: "channel",
    tag: [<Link to="/mid-management/channel">{getTranslator("CHANNEL")}</Link>]
  },
  {
    path: "organization",
    tag: [<Link to="/mid-management/organization">{getTranslator("ORGANIZATION")}</Link>]
  },

  {
    path: "create-organization",
    tag: [<Link to="/mid-management/organization/create-organization">{getTranslator("CREATE_ORGANIZATION")}</Link>]
  },
  {
    path: "edit-organization",
    tag: [<Link to="/mid-management/organization">{getTranslator("EDIT_ORGANIZATION")}</Link>]
  },

  {
    path: "channel-to-merchant",
    tag: [<Link to="/mid-management/merchant/channel-to-merchant">{getTranslator("CHANNEL_TO_MERCHANT")}</Link>]
  },

  {
    path: "add-channel-to-merchant",
    tag: [<Link to="/mid-management/merchant/channel-to-merchant">{getTranslator("ADD_CHANNEL_TO_MERCHANT")}</Link>]
  },

  {
    path: "edit-channel-to-merchant",
    tag: [<Link to="/mid-management/merchant/edit-channel-to-merchant">{getTranslator("EDIT_CHANNEL_TO_MERCHANT")}</Link>]
  },


  {
    path: "get-merchant-secret-key",
    tag: [<Link to="/mid-management/merchant/get-merchant-secret-key">{getTranslator("SHOW_MERCHANT_SECRET_KEY")}</Link>]
  },
  {
    path: "edit-merchant",
    tag: [<Link to="/mid-management/merchant/edit-merchant">{getTranslator("EDIT_MERCHANT_ACCOUNT")}</Link>]
  },
  {
    path: "create-merchant-account",
    tag: [<Link to="/mid-management/merchant/create-merchant-account">{getTranslator("CREATE_MERCHANT_ACCOUNT")}</Link>]
  },
  {
    path: "create-merchant",
    tag: [<Link to="/mid-management/merchant/create-merchant">{getTranslator("CREATE_MERCHANT_ACCOUNT")}</Link>]
  },
  {
    path: "create-channel",
    tag: [<Link to="/mid-management/channel/create-channel">{getTranslator("CREATE_CHANNEL")}</Link>]
  },
  {
    path: "edit-channel",
    tag: [<Link to="/mid-management/channel">{getTranslator("EDIT_CHANNEL")}</Link>]
  },
  {
    path: "merchant-id",
    tag: [<Link to="/mid-management/merchant-id">{getTranslator("MID_LIST")}</Link>]
  },
  {
    path: "reconcile",
    tag: [<Link to="/reconcile">{getTranslator("RECONCILE")}</Link>]
  },
  {
    path: "detail",
    tag: [<Link to="/reconcile/detail">{getTranslator("DETAIL")}</Link>]
  },
  {
    path: "detail",
    tag: [<Link to="/reconcile/detail">{getTranslator("DETAIL")}</Link>]
  },
  {
    path: "summary",
    tag: [<Link to="/reconcile/summary">{getTranslator("SUMMARY")}</Link>]
  },
];