import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { REQUEST_PASSWORD_URL } from "../_redux/authCrud";

import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { post } from "../../Util/HttpClient";
import { withTranslator } from '../../Util/Translator';
import { checkToken } from "../_redux/authCrud";
import { getCaptcha } from "../../Util/Captcha"
import CachedIcon from '@material-ui/icons/Cached';
import { getTranslator } from "../../Util/Common";
import { forgotPasswordSchema } from "./FormSchema/ForgotPasswordSchema"


function ForgotPassword(props) {
  const { match, t } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisplay, setIsDisplay] = useState(true);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(forgotPasswordSchema)
  });
  const [captcha, setCaptcha] = useState("");

  const refreshCaptcha = () => {
    getCaptcha().then((res) => {
      setCaptcha(res.captcha)
      setValue("token", res.token)
    })
  }

  useEffect(() => {
    try {
      if ('token' in match.params) {
        const fetchData = async () => {
          const { data } = await checkToken(match.params['token']);
          if (data) {
            if (!data.error) {
              if (!data.results.success) {
                toast.error(t("TOKEN_NOT_EXIST"));
                props.history.push("/auth/forgot-password")
              }
            }
          }
        };
        fetchData();
      }
    } catch (err) {

    }
  }, []);
  useEffect(() => {
    if (isDisplay) {
      getCaptcha().then((res) => {
        setCaptcha(res.captcha)
        setValue("token", res.token)
      })
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      const result = await post(REQUEST_PASSWORD_URL, data);
      setIsSubmitting(true);
      if (result.error) {
        setIsSubmitting(false);
        toast.error(getTranslator(result.message));
      } else {
        toast.success(t('SUCCESSFUL'));
        setIsSubmitting(false);
        setIsRequested(true);
      };
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getInputClasses = (fieldname) => {
    if (errors[fieldname]) {
      return "is-invalid";
    }

    if (errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };


  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">{t('FORGOTTEN_PASSWORD')}</h3>
            <div className="text-muted font-weight-bold">
              {t('ENTER_YOUR_EMAIL_ADDRESS_BELOW_TO_RESET_YOUR_PASSWORD')}
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {errors.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {errors.status?.message}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                {...register("email", {
                })}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}

              />
              {errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors.email?.message}</div>
                </div>
              ) : null}
            </div>
            {isDisplay && (
              <>
                <div className="form-row captcha-box">
                  <div className="col-4">
                    <img src={captcha} alt="Captcha" />
                  </div>
                  <div className="col-8 input-group">
                    <input
                      className={`form-control form-control-solid h-auto py-5 px-6`}
                      name="captcha"
                      {...register("captcha", {
                      })}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text"><CachedIcon onClick={() => { refreshCaptcha() }} /></div>
                    </div>
                  </div>
                  {errors?.captcha ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors?.captcha?.message}</div>
                    </div>
                  ) : null}

                </div>
                <input
                  className={` d-none `}
                  name="token"
                  {...register("token", {
                  })}

                />
              </>
            )}

            <div className="form-group d-flex flex-wrap flex-center">

              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  {t('CANCEL')}
                </button>
              </Link>
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={isSubmitting}
              >
                {t('SUBMIT')}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default (connect(null, auth.actions)(withTranslator(ForgotPassword)));
