import React from 'react';
import { injectIntl } from 'react-intl';
import { setLanguage } from '../../../_metronic/i18n/Metronici18n';

export function withTranslator(ChildComponent) {
    function ComposedComponent(props) {
        const translate = (intl) => {
            return (message, options = {}) => {
                return intl.formatMessage({ id: message }, options);
            };
        };
        return (
            <ChildComponent
                setLanguage={setLanguage}
                t={translate(props.intl)}
                {...props}
            />
        );
    }
    return injectIntl(ComposedComponent);
}
