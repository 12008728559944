import { getTranslator } from "../../../../../app/modules/Util/Common";

const list = {
  dashboard: {
    path: "/dashboard",
    icon: "icon-home",
    name: getTranslator("DASHBOARD"),
    order: 0,
  },
  transaction: {
    path: "/transaction",
    icon: "fa fa-credit-card",
    name: getTranslator("TRANSACTION"),
    order: 1,
  },
  reconcile: {
    name: getTranslator("RECONCILE"),
    icon: "fa fa-money-bill-alt",
    order: 2,
    submenu: {},
  },
  // reconcile_setting: {
  //   icon: "/media/svg/icons/General/Settings-2.svg",
  //   name: "Reconcile Setting",
  //   path: "/reconcile-setting",
  //   order: 3,
  // },
  finance: {
    icon: "icon-docs",
    name: getTranslator("FINANCE"),
    submenu: {},
    order: 3,
  },
  mid_management: {
    icon: "fa fa-key",
    name: getTranslator("MID"),
    submenu: {},
    order: 4,
  },

  guide: {
    path: "/merchant/guide",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("USER_GUIDE"),
    order: 5,
  },
  faq: {
    path: "/merchant/faq",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("FAQ"),
    order: 6,
  },
  user: {
    icon: "fa fa-user",
    name: getTranslator("USER"),
    submenu: {},
    order: 3,
  },
  statistical_charts: {
    path: "/user",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("STATISTICAL_CHARTS"),
    order: 7,
  },
  alert: {
    path: "/alert",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("ALERT"),
    order: 8,
  },
  audit_trail: {
    path: "/alert",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("AUDIT_TRAIL"),
    order: 9,
  },
  service_center: {
    path: "/alert",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("SERVICE_CENTER"),
    order: 10,
  },
};

const submenu = {
  summary: {
    path: "/reconcile/summary",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("SUMMARY"),
    order: 1,
  },
  detail: {
    path: "/reconcile/detail",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("DETAIL"),
    order: 2,
  },
  bank_fund: {
    path: "/reconcile/bank-fund",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("BANK_FUND"),
    order: 3,
  },
  client_fund: {
    path: "/reconcile/client-fund",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("CLIENT_FUND"),
    order: 4,
  },
  bank_transfer: {
    path: "/reconcile/bank-transfer",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("BANK_TRANSFER"),
    order: 5,
  },
  bank_statement: {
    path: "/reconcile/bank-statement",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("BANK_STATEMENT"),
    order: 6,
  },
  cash_flow: {
    path: "/reconcile/cash-flow",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("CASH_FLOW"),
    order: 7,
  },
  pledged_deposit: {
    path: "/reconcile/pledged-deposit",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("PLEDGED_DEPOSIT"),
    order: 8,
  },
  // pg_key: {
  //   path: "/mid-management/pg-key",
  //   icon: "/media/svg/icons/Design/Layers.svg",
  //   name: "Payment Gateway Key",
  //   order: 4,
  // },
  // mid_secret_key: {
  //   path: "/mid-management/mid-secret-key",
  //   icon: "/media/svg/icons/Design/Layers.svg",
  //   name: "Merchant ID Secret Key",
  //   order: 3,
  // },
  other_payment_category: {
    path: "/finance/other-payment-category",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("OTHER_PAYMENT_CATEGORY"),
    order: 1,
  },
  download_report: {
    path: "/finance/download-report",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("DOWNLOAD_REPORT"),
    order: 2,
  },
  user: {
    path: "/user/show-user",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("USER_LIST"),
    order: 2,
  },
  // set_mid_mapping: {
  //   path: "/user/set-mid-mapping",
  //   icon: "/media/svg/icons/Design/Layers.svg",
  //   name: "Set Mid Mapping",
  //   order: 4,
  // },
  channel: {
    path: "/mid-management/channel",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("CHANNEL"),
    order: 2,
  },

  organization: {
    path: "/mid-management/organization/",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("ORGANIZATION"),
    order: 2,
  },

  merchant: {
    path: "/mid-management/merchant/",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("MERCHANT"),
    order: 2,
  },
  merchant_mid_mapping: {
    path: "/user/set-mid-mapping",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("MID_MAPPING"),
    order: 4,
  },
  mid_secret_key: {
    path: "/mid-management/merchant-id",
    icon: "/media/svg/icons/Design/Layers.svg",
    name: getTranslator("MID_LIST"),
    order: 2,
  },
};
export default function ASideList(permission) {
  let lists = [];
  if (permission === null) {
    return [];
  }
  if (Object.keys(permission).length > 0) {

    Object.keys(permission).map((key) => {
      if (list[key] !== undefined) {
        if ("submenu" in list[key]) {
          Object.keys(permission[key]).map((subkey) => {
            if (subkey !== "user" || subkey !== "admin") {
              if (permission[key][subkey].length !== 0) {
                if (subkey in permission[key]) {
                  list[key]["submenu"][subkey] = submenu[subkey];
                }
              }
            } else {
              list[key]["submenu"][subkey] = submenu["user"];
            }

          });
        }
        if ("submenu" in list[key]) {
          if (Object.keys(list[key].submenu).length !== 0) {
            lists.push(list[key]);
          }
        } else {
          lists.push(list[key]);
        }
      }
    });

  }
  return lists.sort((a, b) => a.order > b.order);
}
