import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as auth from "../_redux/authRedux";
import { confirmPassword, checkToken } from "../_redux/authCrud";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslator } from '../../Util/Translator';
import { post } from "../../Util/HttpClient";
import { getTranslator } from "../../Util/Common";
import { confirmPasswordSchema } from "./FormSchema/ConfirmPasswordSchema";

export const CONFIRM_NEW_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/update-password`;


const initialValues = {
    password: "",
    cPassword: "",
};






function ConfirmPassword(props) {

    const { intl, match } = props;
    const [isRequested, setIsRequested] = useState(false);
    const [submitting, setIsSubmitting] = useState(false);


    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(confirmPasswordSchema)
    });

    useEffect(() => {
        if ('token' in match.params) {
            const fetchData = async () => {
                const result = await checkToken(match.params['token']);
                if (!result.data.error) {
                    if (result.data.results.success) {
                        setValue('token', match.params['token']);
                        return true;
                    } else {
                        return props.history.push('/');
                    }
                } else {
                    return props.history.push('/error');
                }
            };
            fetchData();
        } else {
            return props.history.push('/error');
        }
    }, []);



    const onSubmit = async data => {
        try {
            const result = await post(CONFIRM_NEW_PASSWORD_URL, data);
            setIsSubmitting(true);
            if (result.error) {
                setIsSubmitting(false);
                toast.error(result.message);
            } else {
                if (result.results.error) {
                    setIsSubmitting(false);
                    toast.error(result.results.message);
                }
                else {
                    setIsSubmitting(false);
                    toast.success(getTranslator('SUCCESSFUL'));
                    setIsRequested(true);
                }
            };
        } catch (err) {
            toast.error(err.message);
        }

    };




    const getInputClasses = (fieldname) => {
        if (errors[fieldname]) {
            return "is-invalid";
        }
        if (errors[fieldname]) {
            return "is-valid";
        }
        return "";
    };

    return (
        <>
            {isRequested && <Redirect to="/auth" />}
            {!isRequested && (
                <div className="login-form login-forgot" style={{ display: "block" }}>
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">{getTranslator('CONFIRM_NEW_PASSWORD')}</h3>
                        <div className="text-muted font-weight-bold">
                            {getTranslator('ENTER_YOUR_NEW_PASSWORD_AND_CONFIRM_NEW_PASSWORD_BELOW')}
                        </div>
                    </div>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    >
                        <input
                            hidden
                            {...register("token", {
                            })}
                        />

                        <div className="form-group fv-plugins-icon-container">
                            <input
                                type="password"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "password"
                                )}`}
                                {...register("password", {
                                })}
                            />
                            {errors.password ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{errors.password?.message}</div>
                                </div>
                            ) : null}
                        </div>

                        <div className="form-group fv-plugins-icon-container">
                            <input
                                type="password"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "cPassword"
                                )}`}
                                {...register("cPassword", {
                                })}
                            />
                            {errors.cPassword ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{errors.cPassword?.message}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group float-right ">
                            <input disabled={submitting}
                                className="btn btn-primary  font-weight-bold px-9 py-4"
                                type="submit"
                                value="Submit" />
                        </div>
                    </form>
                </div>
            )}
        </>
    );
}

export default (connect(null, auth.actions)(withTranslator(ConfirmPassword)));
