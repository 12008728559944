import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserRole } from "../../Util/Role";
import { getCaptcha } from "../../Util/Captcha"
import { loginSchema } from "./FormSchema/LoginSchema"
import CachedIcon from '@material-ui/icons/Cached';
import { withTranslator } from '../../Util/Translator';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const login_attempts_limit = Number (process.env.REACT_APP_LOGIN_ATTEMPTS_LIMIT);

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { t } = props;
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [isDisplay, setIsDisplay] = useState(window.sessionStorage.getItem("over_attempts") ?? false);
  const [loginAttempts, setLoginAttempts] = useState(Number((window.localStorage.getItem("login_attempts")) ?? 1));

  const incrementLoginAttempts = () => {
    setLoginAttempts(loginAttempts + 1);
    window.localStorage.setItem("login_attempts", loginAttempts);
  };


  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };


  const succesLogin = ({ results }) => {
    const user = {
      email: results.user.email,
      role: getUserRole(results.user.role),
      status: results.user.status,
      first_name: results.user.contact_info.first_name ?? results.user.contact.first_name,
      last_name: results.user.contact_info.last_name ?? results.user.contact.last_name,
      phone: results.user.contact_info.contact ?? results.user.contact.phone,
      lang: results.user.contact_info.lang ?? results.user.contact.lang,
    };
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("accessToken", results.accessToken);
    localStorage.setItem("refreshToken", results.refreshToken);
    localStorage.setItem("mid", JSON.stringify(results.user.mid));
    localStorage.setItem("userId", results.user._id);
    localStorage.setItem("permission", JSON.stringify(results.user.permission));
    localStorage.setItem("i18nConfig", JSON.stringify({ selectedLang: results.user.contact_info.lang ?? results.user.contact.lang }));
    return results.accessToken;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(async () => {
        login(values)
          .then(({ data }) => {
            disableLoading();
            const token = succesLogin(data);
            window.sessionStorage.removeItem("over_attempts");
            window.localStorage.removeItem("login_attempts");
            toast.success(t('SUCCESSFUL'));
            props.login(token);
          })
          .catch((err) => {
            disableLoading();
            setSubmitting(false);
            if (err.response) {
              incrementLoginAttempts();


              if (loginAttempts >= login_attempts_limit ) {
                setIsDisplay(true);
                refreshCaptcha();
                window.sessionStorage.setItem("over_attempts", true);
              }
            }
            toast.error(t('INVALID_LOGIN'));
            setStatus(t('INVALID_LOGIN'));

          });
      }, 1000);
    },
  });
  useEffect(() => {
    if (isDisplay) {
      getCaptcha().then((res) => {
        setCaptcha(res.captcha)
        formik.setFieldValue("token", res.token)
      })
    }
  }, []);

  const refreshCaptcha = () => {
    getCaptcha().then((res) => {
      setCaptcha(res.captcha)
      formik.setFieldValue("token", res.token)
    })
  }
  return (
    <div className="login-form login-signin m-4" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <img
          src="/media/logos/acqra-icon-250.png"
          alt="logo"
          style={{ width: "100px" }}
          className="logo-default mb-2"
        />
        <h3 className="font-size-h1">
          {t('LOGIN_TO_YOUR_ACCOUNT')}
        </h3>
      </div>
      {/* end::Head */}
      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {/* {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            <div className="alert-text ">
              Use account <strong>admin@demo.com</strong> and password{" "}
              <strong>demo</strong> to continue.
            </div>
          </div>
        )} */}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={t('EMAIL')}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {isDisplay && (
          <>
            <div className="form-row captcha-box">
              <div className="col-4">
                <img src={captcha} alt="Captcha" />
              </div>
              <div className="col-8 input-group">
                <input
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "captcha"
                  )}`}
                  name="captcha"
                  {...formik.getFieldProps("captcha")}
                />
                <div className="input-group-append">
                  <div className="input-group-text"><CachedIcon onClick={() => { refreshCaptcha() }} /></div>
                </div>
              </div>
              {formik.touched.captcha && formik.errors.captcha ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.captcha}</div>
                </div>
              ) : null}

            </div>
            <input
              className={` d-none `}
              {...formik.getFieldProps("token")}
              name="token"
            />
          </>
        )}

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            {t('FORGOT_BUTTON')}
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>{t('SIGN_IN')}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default withTranslator(injectIntl(connect(null, auth.actions)(Login)));
