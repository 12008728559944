
import React from "react";
import * as yup from "yup";
import { passwordSchema } from "../../../Util/FormSchema";
import { getTranslator } from "../../../Util/Common";

export const confirmPasswordSchema = yup.object().shape({
    password: passwordSchema,
    cPassword: passwordSchema.oneOf([yup.ref('password'), null], getTranslator('PASSWORDS_MUST_MATCH'))
})
