import axios from "axios";
import publicIp from "public-ip";
export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/forgot-password`;
export const CONFIRM_NEW_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/update-password`;
export const CHECK_TOKEN_URL = `${process.env.REACT_APP_API_URL}/check-token`;
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;


async function getUserIP() {
  try {
    return await publicIp.v4()
  } catch (err) {
    throw err
  }

}

export async function login({ email, password, token, captcha }) {
  const ip = await getUserIP();
  const param = {
    username: email,
    password: password,
    user_ip: ip,
  };
  if (token) {
    Object.assign(param, { token: token });
    Object.assign(param, { answer: captcha });
  }
  return axios.post(LOGIN_URL, param);
 
}

export function register(data) {
  return axios.post(REGISTER_URL, { email: data.email, fullname: data.fullname, username: data.username, password: data.password });
}

export function requestPassword(email) {
  const response = axios.post(REQUEST_PASSWORD_URL, { email })
  return response;
}

export function confirmPassword(data) {
  const response = axios.post(CONFIRM_NEW_PASSWORD_URL, data);
  return response;
}


export function checkToken(data) {
  const response = axios.post(CHECK_TOKEN_URL, { token: data });
  return response
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  let user = undefined;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  }
  return user;
}
