
import React from "react";
import * as yup from "yup";
import { lazy } from 'yup';
import { getTranslator } from "../../../Util/Common";
import { passwordSchema } from "../../../Util/FormSchema";



export const loginSchema = lazy((data) => {
    const requiredCaptcha = (data.hasOwnProperty('token')) ? yup.number(getTranslator("INVALID_CAPTCHA")).required() : yup.number(getTranslator("INVALID_CAPTCHA"));
    return yup.object().shape({
        email: yup.string().email().max(256, getTranslator('EMAIL_MAXIMUM_256_SYMBOLS')).required(),
        captcha: requiredCaptcha,
        password: passwordSchema
    })
});