import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import {
  LayoutSplashScreen,
} from "../_metronic/layout";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const TransactionPage = lazy(() =>
  import("./modules/Transaction/TransactionPage")
);
const ReconcilePage = lazy(() => import("./modules/Reconcile/ReconcilePage"));
const CommonPage = lazy(() => import("./modules/Common/CommonPage"));
const UserPage = lazy(() => import("./modules/User/UserPage"));
const MidManagementPage = lazy(() =>
  import("./modules/MidManagement/MidManagementPage")
);
export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/transaction" />
        <Route path="/profile" component={UserProfilepage} />
        <Route path="/reconcile" component={ReconcilePage} />
        <Route path="/mid-management" component={MidManagementPage} />
        <Route path="/user" component={UserPage} />
        <Route path="/transaction" component={TransactionPage} />
        <Route path="/" component={CommonPage} />
        <Redirect to="/error/" />
      </Switch>
    </Suspense>
  );
}
