import React, { useState } from "react";
import axios from "axios";


const getCaptchaUrl = `${process.env.REACT_APP_API_URL}/get-captcha`;



export const getCaptcha = async () => {
    try {
        const result = (await axios.get(getCaptchaUrl, {})).data;
        return result;

    } catch (err) {
        throw err
    }
}
export const refreshCaptcha = async () => {
    try {
        const result = (await axios.get(getCaptchaUrl, {})).data;
        console.log(result.captcha)
        return result.captcha;

    } catch (err) {
        throw err
    }
}