import React, { useEffect, useState } from "react";
import { Route ,useHistory} from "react-router-dom";
import { post,checkRefreshToken } from "../../../../app/modules/Util/HttpClient";

import { PermissionContent } from "./PermissionContent";
const checkCurrentPermissionUrl = `${process.env.REACT_APP_API_URL}/check-current-permission`;

export const PermissionRoute = ({
  children,
  route,
  component,
  render,
  ...props
}) => {
  props.location.action = route;
  const [isLoading , setIsLoading ]= useState(true);
  const history = useHistory();
  const checkExpired = async () => {
    let response = await post(checkCurrentPermissionUrl, {
      action: `${route}`,
    });

    if (
      localStorage.getItem("accessToken") == null &&
      localStorage.getItem("refreshToken") == null
    ) {
      history.push("/auth/login");
    }
    if (response.code === 401) {
			const newToken = await checkRefreshToken();
			if(newToken){
        await post(checkCurrentPermissionUrl, {
          action: `${route}`,
        });
        if (response.code === 500) {
          history.push("/error/internal-server-error");
        }
      }else{
        history.push("/error/permission-denied");
      }
		}
    setIsLoading(false)
  };
  useEffect(()=>{checkExpired()},[])
  if(isLoading){
    return ""
  }
  return (
    <Route {...props}>
      {(routeProps) => {
        if (typeof children === "function") {
          return <PermissionContent>{children(routeProps)}</PermissionContent>;
        }

        if (!routeProps.match) {
          return null;
        }

        if (children) {
          return <PermissionContent>{children}</PermissionContent>;
        }

        if (component) {
          return (
            <PermissionContent>
              {React.createElement(component, routeProps)}
            </PermissionContent>
          );
        }

        if (render) {
          return <PermissionContent>{render(routeProps)}</PermissionContent>;
        }

        return null;
      }}
    </Route>
  );
};
