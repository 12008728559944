export const boolOption = [
    { value: true, label: "True" },
    { value: false, label: "False" },
];

export const YNOption = [
    { value: "Y", label: "Y" },
    { value: "N", label: "N" },
];


export const platformList = [
    { value: "cybersource", label: "Cybersource" },
    { value: "worldpay", label: "Worldpay" },
];

export const threeDSModeList = [
    { value: "NORMAL", label: "Normal, enable_3ds=Y/N, perform 3ds payment if card support" },
    { value: "MUST", label: "Must, enable_3ds=Y, card must support 3ds payment" },
    { value: "MUSTFORCE", label: "MustForce, enable_3ds=Y, pass if liability shifted" },
    { value: "FORCE", label: "Force, enable_3ds=Y, perform 3ds payment if card support" },
    { value: "DISABLED", label: "Disabled, enabled_3ds=N, never perform 3ds payment" },
];
export const worldpaythreeDSModeList = [
    { value: "NORMAL", label: "Normal, enable_3ds=Y/N, perform 3ds payment if card support" },
    { value: "MUSTFORCE", label: "MustForce, enable_3ds=Y, pass if liability shifted" },
    { value: "FORCE", label: "Force, enable_3ds=Y, perform 3ds payment if card support" },
    { value: "DISABLED", label: "Disabled, enabled_3ds=N, never perform 3ds payment" },
];




export const paymentModeOptions = [
    { value: "ALL", label: "ALL" },
    { value: "API", label: "API" },
    { value: "UI", label: "UI" },
];

export const paymentModeOption = [
    { value: "API", label: "API" },
    { value: "UI", label: "UI" },
];
export const otherPaymentModeOption = [
    { value: "CREDIT_CARD", label: "Credit Card" },
    { value: "DIGITAL_WALLET", label: "Digital Wallet" },
];
export const supportCardTypeOption = [
    { value: "VISA", label: "Visa" },
    { value: "MASTERCARD", label: "MasterCard" },
    { value: "AMERICAN_EXPRESS", label: "American Express" },
    { value: "DISCOVER_CARD", label: "Discover Card" },
    { value: "DINERS_CLUB", label: "Diners Club" },
    { value: "JCB", label: "JCB" },
    { value: "UNIONPAY", label: "UnionPay" },
];

export const supportCardTypeOptionForChannel = [
    { value: "visa", label: "Visa" },
    { value: "mastercard", label: "MasterCard" },
    { value: "unionpay", label: "UnionPay" },
];
export const supportDigitalWalletTypeOption = [
    { value: "PAYME", label: "PayMe" },
    { value: "WECHATPAYHK", label: "WeChatPayHK" },
    { value: "ALIPAYHK", label: "AlipayHK" },
];
export const allowCardTypeOption = [
    { value: "visa", label: "Visa" },
    { value: "mastercard", label: "Mastercard" },
    { value: "american_express", label: "American Express" },
    { value: "discover_card", label: "Discover Card" },
    { value: "diners_club", label: "Diners Club" },
    { value: "jcb", label: "JCB" },
    { value: "unionpay", label: "UnionPay" },
    { value: "maestro", label: "Maestro" },
];
export const statusList = [
    { value: "ACTIVE", label: "Active" },
    { value: "DISABLED", label: "Disabled" },
    { value: "SUSPENDED", label: "Suspended" },
];

export const txnDisplayLangList = [
    { value: "en", label: "en" },
    { value: "zh", label: "zh" },
    { value: "cn", label: "cn" },
    { value: "fr", label: "fr" },
    { value: "es", label: "es" },
    { value: "it", label: "it" },

];

export const internalUserRoles = [
    { value: "SUPER_ADMIN", label: "SUPER_ADMIN" },
    { value: "INTERNAL_ADMIN", label: "INTERNAL_ADMIN" },
    { value: "INTERNAL_USER", label: "INTERNAL_USER" },

];
export const externalUserRoles = [
    { value: "EXTERNAL_ADMIN", label: "EXTERNAL_ADMIN" },
    { value: "EXTERNAL_USER", label: "EXTERNAL_USER" },

];

export const transactionAction = [
    { value: "AUTH", label: "Auth" },
    { value: "CAPTURE", label: "Capture" },
    { value: "PAYMENT", label: "Payment" },
    { value: "REFUND", label: "Refund" },
    { value: "VOID_AUTH", label: "Void Auth" },
    { value: "VOID_REFUND", label: "Void Refund" },
];
export const transactionStatus = [
    { value: "SUCCESS", label: "Success" },
    { value: "3DS_OPEN", label: "3DS Open" },
    { value: "FAILED", label: "Failed" },
    { value: "SUBMITTED", label: "Submitted" },
    { value: "EXPIRED", label: "Expired" },
    { value: "ERROR", label: "Error" },
    { value: "VOIDED", label: "Voided" },
];


export const rowsPerPageOptions = [10, 20, 25, 50, 80, 100];

export const threedsProcessor = [
    { value: "610ba829dbf3837d5314747e", label: "worldpay (HKD)" },
    { value: "6135cd3368c2968f80d4ac16", label: "worldpay (USD)" },
    { value: "60e3fadab1351227fa8f7e25", label: "cybersource" },
];

export const allowCardTypeOptionTable = [
    { value: "visa", label: "Visa" },
    { value: "master", label: "MC" },
    { value: "mastercard", label: "MC" }
]

export const avsMode = [
    { value: "REQUIRED", label: "Perform AVS, Require billing address and perform AVS" },
    { value: "OPTIONAL", label: "Address Only, Require billing address, but no perform AVS" },
    { value: "DISABLED", label: "Not Require, Do not require billing address" },
]

export const recurringPaymentMode = [
    { value: "REQUIRED", label: "Required" },
    { value: "OPTIONAL", label: "Optional" },
    { value: "DISABLED", label: "Disabled" },
];


export const threedsOption = [
    { value: "REQUIRED", label: "Required" },
    { value: "OPTIONAL", label: "Optional" },
    { value: "DISABLED", label: "Disabled" },
]


export const merchantAvsMode = [
    { value: "REQUIRED", label: "Require Billing Address" },
    { value: "OPTIONAL", label: "Require Billing Address" },
    { value: "DISABLED", label: "Not Require Billing Address" },
    { value: "NOTSUBMITTED", label: "Require Billing Address" },
]





export const worldpayAVSCode = [
    { label: "Postcode and Address Matched", value: "A" },
    { label: "Postcode Matched; Address not Checked", value: "B" },
    { label: "Postcode Matched; Address not Checked", value: "C" },
    { label: "Address Matched; Postcode not Checked", value: "D" },
    { label: "Postcode and Address not Checked", value: "E" },
    { label: "Address Matched; Postcode not Matched", value: "F" },
    { label: "Postcode not Checked; Address not Matched", value: "G" },
    { label: "Postcode and Address not Supplied by Shopper/Merchant", value: "H" },
    { label: "Address not Checked; Postcode not  Matched", value: "I" },
    { label: "Postcode and Address not Matched", value: "J" },
    { label: "Postcode and Address Matched", value: "WA" },
    { label: "Postcode Matched; Address not Checked", value: "WB" },
    { label: "Postcode Matched; Address not Checked", value: "WC" },
    { label: "Address Matched; Postcode not Checked", value: "WD" },
    { label: "Postcode and Address not Checked", value: "WE" },
    { label: "Address Matched; Postcode not Matched", value: "WF" },
    { label: "Postcode not Checked; Address not Matched", value: "WG" },
    { label: "Postcode and Address not Supplied by Shopper/Merchant", value: "WH" },
    { label: "Address not Checked; Postcode not  Matched", value: "WI" },
    { label: "Postcode and Address not Matched", value: "WJ" },
];


export const cybsAVSCode = [
    { label: "Partial match", value: "A" },
    { label: "Partial match", value: "B" },
    { label: "No match", value: "C" },
    { label: "Match", value: "D" },
    { label: "Invalid", value: "E" },
    { label: "Partial match", value: "F" },
    { label: "Not Support", value: "G" },
    { label: "Partial match", value: "H" },
    { label: "No match", value: "I" },
    { label: "Match", value: "J" },
    { label: "Partial match", value: "K" },
    { label: "Partial match", value: "L" },
    { label: "Match", value: "M" },
    { label: "No match", value: "N" },
    { label: "Partial match", value: "O" },
    { label: "Partial match", value: "P" },
    { label: "Match", value: "Q" },
    { label: "System unavailable", value: "R" },
    { label: "Not supported", value: "S" },
    { label: "Partial match", value: "T" },
    { label: "System unavailable", value: "U" },
    { label: "Match", value: "V" },
    { label: "Partial match", value: "W" },
    { label: "Match", value: "X" },
    { label: "Match", value: "Y" },
    { label: "Partial match", value: "Z" },
    { label: "Not supported", value: "1" },
    { label: "Unrecognized", value: "2" },
    { label: "Match", value: "3" },
    { label: "No match", value: "4" },
    { label: "Partial match", value: "CA" },
    { label: "Partial match", value: "CB" },
    { label: "No match", value: "CC" },
    { label: "Match", value: "CD" },
    { label: "Invalid", value: "CE" },
    { label: "Partial match", value: "CF" },
    { label: "Not Support", value: "CG" },
    { label: "Partial match", value: "CH" },
    { label: "No match", value: "CI" },
    { label: "Match", value: "CJ" },
    { label: "Partial match", value: "CK" },
    { label: "Partial match", value: "CL" },
    { label: "Match", value: "CM" },
    { label: "No match", value: "CN" },
    { label: "Partial match", value: "CO" },
    { label: "Partial match", value: "CP" },
    { label: "Match", value: "CQ" },
    { label: "System unavailable", value: "CR" },
    { label: "Not supported", value: "CS" },
    { label: "Partial match", value: "CT" },
    { label: "System unavailable", value: "CU" },
    { label: "Match", value: "CV" },
    { label: "Partial match", value: "CW" },
    { label: "Match", value: "CX" },
    { label: "Match", value: "CY" },
    { label: "Partial match", value: "CZ" },
    { label: "Not supported", value: "C1" },
    { label: "Unrecognized", value: "C2" },
    { label: "Match", value: "C3" },
    { label: "No match", value: "C4" },
];


export const cybsCVNCode = [
    { label: "The transaction was considered suspicious by the issuing bank.", value: "D" },
    { label: "The CVN failed the processor", value: "I" },
    { label: "The CVN matched.", value: "M" },
    { label: "The CVN did not match.", value: "N" },
    { label: "The CVN was not processed by the processor for an unspecified reason.", value: "P" },
    { label: "The CVN is on the card but was not included in the request.", value: "S" },
    { label: "Card verification is not supported by the issuing bank.", value: "U" },
    { label: "Card verification is not supported by the card association.", value: "X" },
    { label: "Card verification is not supported for this processor or card type.", value: "1" },
    { label: "An unrecognized result code was returned by the processor for the card verification response.", value: "2" },
    { label: "No result code was returned by the processor.", value: "3" },
];



export const worldpayCVNCode = [
    { label: "Approved", value: "A" },
    { label: "Not Supplied by Shopper", value: "B" },
    { label: "Not Sent to the Acquirer", value: "C" },
    { label: "Failed", value: "D" },
];


export const channelAvsMode = [
    { value: "REQUIRED", label: "Perform AVS, Require billing address and perform AVS" },
    { value: "OPTIONAL", label: "Address Only, Require billing address, but no perform AVS" },
    { value: "DISABLED", label: "Not Require, Do not require billing address" },
]



export const eciList = [
    { value: "00", label: "00" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "05", label: "05" },
    { value: "06", label: "06" },
    { value: "07", label: "07" },
    { value: "", label: "Empty" },

]



export const paymentTypeList = [
    { value: "", label: "Normal Payment" },
    { value: "NO_CVV", label: "No CVV Payment" },
    { value: "TOKEN", label: "Token Payment" }
];


export const mitTypes = [
    { value: "INSTALMENT", label: "Instalment" },
    { value: "RECURRING", label: "Recurring" },
    { value: "UNSCHEDULED", label: "Unscheduled" },
];

export const mitModes = [
    { value: "NONE", label: "None" },
    { value: "INITIAL", label: "Initial" },
    { value: "REPEATED", label: "Repeated" },
];
