import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { checkToken } from "../_redux/authCrud";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslator } from '../../Util/Translator';

export const REDIRECT_CONFIRM_PASSWORD = `/auth/confirm-password/`;





function ActiveAccount(props) {
    const { intl, match, t } = props;
    const [counter, setCounter] = useState(5);
    useEffect(() => {
        if ('token' in match.params) {
            const fetchData = async () => {
                const { data } = await checkToken(match.params['token']);
                if (data) {
                    if (!data.error) {
                        if (!data.results.success) {
                            toast.error(t("TOKEN_NOT_EXIST"));
                            props.history.push("/")
                        }
                    }
                }
            };
            fetchData();
        } else {
            return props.history.push('/error');
        }


    }, []);
    useEffect(() => {
        if (counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000);
        } else {
            props.history.push(`${REDIRECT_CONFIRM_PASSWORD}${match.params['token']}`)
        }

    });
    return (
        <>
            <div className="activation-form" style={{ display: "block" }}>

                <ToastContainer />
                <div className="text-center mb-10 mb-lg-20">
                    <h3 className="font-size-h1">{t('ACTIVATE_ACCOUNT_SUCCESSFUL')}</h3>
                    <div className="text-muted font-weight-bold">
                      {t('ACTIVATE_ACCOUNT_SUCCESSFUL_CONTENT',{counter:counter})}
                    </div>
                </div>

            </div>
        </>
    );
}

export default (connect(null, auth.actions)(withTranslator(ActiveAccount)));
