import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PermissionDenied from "./PermissionDenied";
import NotFound from "./NotFound";
import InternalServerError from "./InternalServerError"
export default function ErrorsPage() {
  return (
    <Switch>
      <Redirect from="/error" exact={true} to="/error/not-found" />
      <Route path="/error/permission-denied" component={PermissionDenied} />
      <Route path="/error/not-found" component={NotFound} />
      <Route path="/error/internal-server-error" component={InternalServerError} />
    </Switch>
  );
}
