export const statusMessage = {
	"10000": {
		message: "PAYMENT_SUCCESS",
	},
	"10001": {
		message: "PAYMENT_OPEN",
	},
	"10002": {
		message: "PAYMENT_EXPIRED",
	},
	"10003": {
		message: "PAYMENT_ERROR",
	},
	"10004": {
		message: "PAYMENT_CANCELED",
	},
	"10005": {
		message: "AUTHORIZATION_SUCCESS",
	},
	"10006": {
		message: "AUTHORIZATION_FAIL",
	},
	"10007": {
		message: "CAPTURE_SUCCESS",
	},
	"10008": {
		message: "CAPTURE_FAIL",
	},
	"10009": {
		message: "3DS_FAIL",
	},
	"10010": {
		message: "PAYMENT_IS_OPEN_FOR_3DS",
	},
	"10011": {
		message: "VOID_AUTHORIZATION_SUCCESS",
	},
	"10012": {
		message: "VOID_AUTHORIZATION_FAIL",
	},
	"10013": {
		message: "VOID_REFUND_SUCCESS",
	},
	"10014": {
		message: "VOID_REFUND_FAIL",
	},
	"10015": {
		message: "SUBMIT_SHIPPING_URL_SUCCESS",
	},
	"10016": {
		message: "SUBMIT_SHIPPING_URL_FAIL",
	},
	"10017": {
		message: "VOID_AUTHORIZATION_SUBMITTED",
	},
	"10018": {
		message: "VOID_REFUND_SUBMITTED",
	},
	"10019": {
		message: "MULTIPLE_CAPTURE_NOT_ALLOWED",
	},
	"10020": {
		message: "INVALID_REQUEST_AUTH_ALREADY_CAPTURED",
	},
	"10021": {
		message: "INVALID_REQUEST_NOT_YET_CAPTURE",
	},
	"10022": {
		message: "3DS_REQUESTED",
	},
	"10023": {
		message: "3DS_DIDNT_PASS",
	},
	"10024": {
		message: "3DS_PASSED",
	},
	"11000": {
		message: "REFUND_SUBMITTED",
	},
	"11001": {
		message: "REFUND_SUCCESS",
	},
	"11002": {
		message: "REFUND_FAIL",
	},
	"11003": {
		message: "MANUAL_REFUND_SUBMITTED",
	},
	"11004": {
		message: "MANUAL_REFUND_SUCCESS",
	},
	"11005": {
		message: "MANUAL_REFUND_FAIL",
	},
	"11006": {
		message: "CAPTURE_SUBMITTED",
	},
	"11007": {
		message: "INVALID_AMOUNT",
	},
	"11008": {
		message: "PENDING",
	},
	"20000": {
		message: "INVALID_REQUEST",
	},
	"20001": {
		message: "INVALID_MASTER_KEY_OR_MASTER_KEY_NOT_FOUND",
	},
	"20002": {
		message: "MERCHANT_ACCOUNT_NOT_ACTIVE",
	},
	"20003": {
		message: "SYSTEM_CURRENTLY_UNAVAILABLE",
	},
	"20004": {
		message: "REQUEST_CURRENTLY_UNAVAILABLE",
	},
	"20005": {
		message: "UNABLE_TO_CREATE_TRANSACTION_RECORD",
	},
	"20006": {
		message: "PLEASE_TRY_AGAIN_LATER",
	},
	"20007": {
		message: "INVALID_USER_ROLE",
	},
	"20008": {
		message: "INVALID_CHANNEL_OR_CHANNEL_NOT_FOUND",
	},
	"20009": {
		message: "INVALID_MERCHANT_CHANNEL_RELATION_OR_RELATION_NOT_FOUND",
	},
	"20010": {
		message: "MERCHANT_CHANNEL_RELATION_ALREADY_EXIST",
	},
	"20011": {
		message: "INVALID_THREEDS_CONDITION",
	},
	"20012": {
		message: "INVALID_RECURRING_PAYMENT_CONDITION",
	},
	"20013": {
		message: "THREEDS_PROCESSOR_NOT_EXISTS_CODE",
	},
	"20014": {
		message: "PRIORITY_GREATER_ONE_CODE",
	},
	"20015": {
		message: "PAYMENT_GATEWAY_NOT_EXISTS_CODE",
	},
	"20016": {
		message: "AMOUNT_EXCEEDED_LIMIT",
	},
	"20017": {
		message: "INVALID_CURRENCY",
	},
	"20018": {
		message: "PAYMENT_GROUP_NOT_EXISTS_CODE",
	},
	"20019": {
		message: "PAYMENT_TOKEN_EXPIRED_CODE",
	},
	"40000": {
		message: "INVALID_API_KEY_OR_API_KEY_NOT_FOUND",
	},
	"40001": {
		message: "INVALID_MID_OR_MID_NOT_FOUND",
	},
	"40002": {
		message: "INVALID_ORDER_REFERENCE",
	},
	"40003": {
		message: "ORDER_REFERENCE_ALREADY_EXIST",
	},
	"40004": {
		message: "AMOUNT_IS_MISSING_OR_INVALID",
	},
	"40005": {
		message: "INVALID_VERSION",
	},
	"40006": {
		message: "CURRENCY_IS_MISSING_OR_INVALID",
	},
	"40007": {
		message: "INVALID_LANGUAGE",
	},
	"40008": {
		message: "INVALID_CONTACT",
	},
	"40009": {
		message: "INVALID_TRANSACTION_ID",
	},
	"40010": {
		message: "TRANSACTION_ID_DOES_NOT_EXIST",
	},
	"40011": {
		message: "INVALID_TRANSACTION_DATE",
	},
	"40012": {
		message: "INVALID_HASH_VALUE",
	},
	"40013": {
		message: "EMAIL_FORMAT_IS_INVALID",
	},
	"40014": {
		message: "CREDIT_CARD_NUMBER_IS_MISSING_OR_INVALID",
	},
	"40015": {
		message: "CVV_IS_MISSING_OR_INVALID",
	},
	"40016": {
		message: "CREDIT_CARD_EXPIRY_MONTH_IS_MISSING_OR_INVALID",
	},
	"40017": {
		message: "CREDIT_CARD_EXPIRY_YEAR_IS_MISSING_OR_INVALID",
	},
	"40018": {
		message: "BILLING_FIRST_NAME_IS_MISSING_OR_INVALID",
	},
	"40019": {
		message: "BILLING_LAST_NAME_IS_MISSING_OR_INVALID",
	},
	"40020": {
		message: "NAME_ON_CARD_IS_MISSING_OR_INVALID",
	},
	"40021": {
		message: "BILLING_ADDRESS_IS_MISSING_OR_INVALID",
	},
	"40022": {
		message: "BILLING_CITY_IS_MISSING_OR_INVALID",
	},
	"40023": {
		message: "BILLING_STATE_IS_MISSING_OR_INVALID",
	},
	"40024": {
		message: "BILLING_ZIP_CODE_IS_MISSING_OR_INVALID",
	},
	"40025": {
		message: "BILLING_COUNTRY_IS_MISSING_OR_INVALID",
	},
	"40026": {
		message: "SHIPPING_ADDRESS_IS_MISSING_OR_INVALID",
	},
	"40027": {
		message: "SHIPPING_CITY_IS_MISSING_OR_INVALID",
	},
	"40028": {
		message: "SHIPPING_STATE_IS_MISSING_OR_INVALID",
	},
	"40029": {
		message: "SHIPPING_ZIP_CODE_IS_MISSING_OR_INVALID",
	},
	"40030": {
		message: "SHIPPING_COUNTRY_IS_MISSING_OR_INVALID",
	},
	"40031": {
		message: "PAYMENT_METHOD_IS_INVALID",
	},
	"40032": {
		message: "BUYER_IP_ADDRESS_IS_MISSING_OR_INVALID",
	},
	"40033": {
		message: "BUYER_BROWSER_USER_AGENT_IS_MISSING_OR_INVALID",
	},
	"40034": {
		message: "BUYER_BROWSER_ACCEPT_LANGUAGE_IS_MISSING_OR_INVALID",
	},
	"40035": {
		message: "DESCRIPTOR_IS_INVALID",
	},
	"40036": {
		message: "SETTLEMENT_REFERENCE_IS_INVALID",
	},
	"40037": {
		message: "ENABLE_3DS_FIELD_IS_MISSING_OR_INVALID",
	},
	"40038": {
		message: "SUCCESS_URL_IS_REQUIRED_WHEN_3DS_IS_ENABLED",
	},
	"40039": {
		message: "SUCCESS_URL_IS_INVALID",
	},
	"40040": {
		message: "ORDER_REFERENCE_DOES_NOT_EXIST",
	},
	"40041": {
		message: "TRANSACTION_AMOUNT_EXCEED_OR_BELOW_LIMIT",
	},
	"40042": {
		message: "FAIL_URL_FORMAT_IS_INVALID",
	},
	"40043": {
		message: "MID_OR_API_KEY_IS_INVALID",
	},
	"40044": {
		message: "NOTIFY_URL_FORMAT_IS_INVALID",
	},
	"40045": {
		message: "MID_IS_SUSPENDED",
	},
	"40046": {
		message: "MID_IS_DISABLED",
	},
	"40047": {
		message: "CARD_TYPE_NOT_SUPPORTED",
	},
	"40048": {
		message: "SHIPPING_URL_IS_MISSING_OR_INVALID",
	},
	"40049": {
		message: "ITEM_FIELD_IS_MISSING_OR_INVALID",
	},
	"40050": {
		message: "ITEM_NAME_IS_MISSING_OR_INVALID",
	},
	"40051": {
		message: "ITEM_PRICE_IS_INVALID",
	},
	"40052": {
		message: "ITEM_QUANTITY_IS_INVALID",
	},
	"40053": {
		message: "TOKEN_IS_INVALID",
	},
	"40054": {
		message: "ORIGINAL_TRANSACTION_IS_NOT_ENABLED_FOR_FOLLOW_UP_TRANSACTION",
	},
	"40055": {
		message: "REFERER_IS_MISSING_OR_INVALID",
	},
	"40056": {
		message: "MERCHANT_ADDRESS_IS_MISSING_OR_INVALID",
	},
	"40057": {
		message: "MERCHANT_ADDRESS_IS_MISSING_OR_INVALID",
	},
	"40058": {
		message: "MERCHANT_CITY_IS_MISSING_OR_INVALID",
	},
	"40059": {
		message: "MERCHANT_STATE_IS_MISSING_OR_INVALID",
	},
	"40060": {
		message: "MERCHANT_COUNTRY_IS_MISSING_OR_INVALID",
	},
	"40061": {
		message: "MERCHANT_CONTACT_IS_MISSING_OR_INVALID",
	},
	"40062": {
		message: "MERCHANT_ZIP_CODE_IS_MISSING_OR_INVALID",
	},
	"40063": {
		message: "REQUEST_ID_IS_MISSING_OR_INVALID",
	},
	"40064": {
		message: "MERCHANT_INFORMATION_IS_MISSING_OR_INVALID",
	},
	"40065": {
		message: "ITEM_URL_IS_MISSING_OR_INVALID",
	},
	"40066": {
		message: "ITEM_IAMGE_URL_IS_INVALID",
	},
	"40067": {
		message: "MID_ALREADY_EXIST",
	},
	"40068": {
		message: "CREDIT_CARD_EXPIRED",
	},
	"40069": {
		message:
			"CAPTURE_OR_REFUND_AMOUNT_GREATER_THAN_ORIGINAL_TRANSACTION_AMOUNT",
	},
	"40070": {
		message: "CAPTURE_OR_REFUND_EXCEED_TIME_LIMIT",
	},
	"40071": {
		message: "RECURRING_SETTING_ERROR",
	},
	"40072": {
		message: "THREEDS_SETTING_ERROR",
	},
	"40073": {
		message: "INSTALMENT_SETTING_ERROR",
	},
	"40074": {
		message: "MERCHANT_NAME_IS_INVALID",
	},
	"40075": {
		message: "MERCHANT_WEBSITE_URL_IS_INVALID",
	},
	"40076": {
		message: "MERCHANT_EMAIL_IS_INVALID",
	},
	"40077": {
		message: "TOKEN_IS_REQUIRED",
	},
	"40078": {
		message: "INVALID_STORE_CREDENTIALS_OPTION",
	},
	"40079": {
		message: "INVALID_OR_REQUIRED_SUBSEQUENCE_OPTION",
	},
	"40080": {
		message: "INVALID_DEVICE_ID",
	},
	"40081": {
		message: "INVALID_WALLET_TYPE",
	},
	"45000": {
		message: "ISSUER_DECLINE_THE_TRANSACTION_NO_SPECIFIC_REASON",
	},
	"45001": {
		message: "SOFT_DECLINED_AVS_CHECK_FAILED",
	},
	"45002": {
		message: "SOFT_DECLINED_CVN_FAILED",
	},
	"45003": {
		message: "ERROR_CONNECTION_TO_PAYMENT_CHANNEL_OR_CARD_SCHEME",
	},
	"45004": {
		message: "BANK_OR_PROCESSOR_DOES_NOT_SUPPORT_THIS_CARD_TYPE",
	},
	"45005": {
		message: "BANK_OR_PROCESSOR_DOES_NOT_SUPPORT_THIS_CURRENCY",
	},
	"45006": {
		message: "BANK_OR_PROCESSOR_DOES_NOT_SUPPORT_THIS_ISSUER_OR_ISSUER_COUNTRY",
	},
	"45007": {
		message: "STOLEN_OR_LOST_CARD",
	},
	"45008": {
		message: "CARD_REACHED_THE_CREDIT_LIMIT",
	},
	"45009": {
		message: "CANNOT_REFUND_AFTER_180_DAYS",
	},
	"45010": {
		message: "RISKED_TRANSACTION",
	},
	"45011": {
		message: "INSUFFICIENT_FUND_IN_THE_ACCOUNT",
	},
	"45012": {
		message: "INVALID_CARD_NUMBER",
	},
	"45013": {
		message: "EXPIRED_CARD",
	},
	"45014": {
		message: "CVV_CVN_DOES_NOT_MATCH",
	},
	"45015": {
		message:
			"ATTEMPTED_TO_PERFORM_3DS_BUT_EITHER_THE_CARDHOLDER_OR_ISSUER_WAS_NOT_PARTICIPATING",
	},
	"45016": {
		message: "UNABLE_TO_PERFORM_3DS",
	},
	"45017": {
		message:
			"INVALID_CARD_OR_CARD_DOES_NOT_SUPPORT_CARD_NOT_PRESENT_TRANSACTION",
	},
	"45018": {
		message: "RISK_TRANSACTION",
	},
	"45019": {
		message: "MCC_NOT_ALLOW",
	},
	"45020": {
		message: "MANUAL_CAPTURE_REQUIRED",
	},
	"45021": {
		message: "BILLING_INFORMATION_IS_INVALID",
	},
	"45022": {
		message: "INVALID_3DS_VERSION",
	},
	"45023": {
		message: "INVALID_3DS_PLATFORM",
	},
	"45024": {
		message: "ERROR_GENERATING_3DS2_JWT",
	},
	"46001": {
		message: "AVS_CHECK_FAILED",
	},
	"45025": {
        "message": "DIGITAL_WALLET_NOT_ENOUGH_CREDIT"
    },
    "45026": {
        "message": "QR_CODE_ALREADY_PAID"
    },
    "45027": {
        "message": "QR_CODE_HAS_EXPIRED"
    },
    "45028": {
        "message": "BALANCE_DEDUCTION_BUT_PAYMENT_EXCEED_TIME_LIMIT"
    },
    "45029": {
        "message": "TRANSACTION_ALREADY_REFUNDED_OR_CANCELLED"
    },
    "45030": {
        "message": "TRANSACTION_NOT_PAID"
    }
};

export const statusMessageForTxn = {
	"10000": {
		"message": "PAYMENT_SUCCESS"
	},
	"10001": {
		"message": "PAYMENT_OPEN"
	},
	"10002": {
		"message": "PAYMENT_EXPIRED"
	},
	"10003": {
		"message": "PAYMENT_ERROR"
	},
	"10004": {
		"message": "PAYMENT_CANCELED"
	},
	"10005": {
		"message": "AUTHORIZATION_SUCCESS"
	},
	"10006": {
		"message": "AUTHORIZATION_FAIL"
	},
	"10007": {
		"message": "CAPTURE_SUCCESS"
	},
	"10008": {
		"message": "CAPTURE_FAIL"
	},
	"10009": {
		"message": "3DS_FAIL"
	},
	"10010": {
		"message": "PAYMENT_IS_OPEN_FOR_3DS"
	},
	"10011": {
		"message": "VOID_AUTHORIZATION_SUCCESS"
	},
	"10012": {
		"message": "VOID_AUTHORIZATION_FAIL"
	},
	"10013": {
		"message": "VOID_REFUND_SUCCESS"
	},
	"10014": {
		"message": "VOID_REFUND_FAIL"
	},
	"10017": {
		"message": "VOID_AUTHORIZATION_SUBMITTED"
	},
	"10018": {
		"message": "VOID_REFUND_SUBMITTED"
	},
	"11000": {
		"message": "REFUND_SUBMITTED"
	},
	"11001": {
		"message": "REFUND_SUCCESS"
	},
	"11002": {
		"message": "REFUND_FAIL"
	},
	"11003": {
		"message": "MANUAL_REFUND_SUBMITTED"
	},
	"11004": {
		"message": "MANUAL_REFUND_SUCCESS"
	},
	"11005": {
		"message": "MANUAL_REFUND_FAIL"
	},
	"11006": {
		"message": "CAPTURE_SUBMITTED"
	},
	"11008": {
		"message": "PENDING"
	},
	
}