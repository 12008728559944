
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { post } from "./HttpClient";
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import { allowCardTypeOptionTable, supportCardTypeOptionForChannel, cybsAVSCode, worldpayCVNCode, cybsCVNCode, worldpayAVSCode, supportDigitalWalletTypeOption, mitTypes, mitModes } from "./InitValues";
import Decimal from 'decimal.js';
import { statusMessage } from "./StatusMessage";
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage } from "react-intl";

export const GetRecords = (url, action) => {
    const [records, setRecords] = useState([]);
    useEffect(() => {
        try {
            const fetchData = async () => {
                const result = await post(url, { action: action });
                if (!result.error) {
                    setRecords(result.results);
                } else {
                    setRecords([]);
                    toast.error(result.msg ?? result.message);
                }
            };
            fetchData();
        } catch (err) {
            setRecords([]);
            toast.error(err.message);
        }
    }, []);

    return records;
};



export const getFilterRecords = (url, param) => {
    try {
        const fetchData = async () => {
            const result = await post(url, param);
            if (!result.error) {
                return result.results;
            } else {
                return [];
            }
        };
        return fetchData();
    } catch (err) {
        toast.error(err.message);
        return [];
    }
};





export const datetimeConvert = (date) => {
    return moment(date).format('YYYY-MM-DD HH:mm:ss').toString();
}
export const dateConvert = (date) => {
    return moment.utc(date).local().format('YYYY-MM-DD');
}

export const toolTip = (content, title) => {
    return (
        <>
            <Tooltip title={title} aria-label={title}>

                {content}

            </Tooltip>
        </>
    )
}


export const mapCardType = (data) => {
    const card = allowCardTypeOptionTable.filter(({ value }) => value.toUpperCase() === data.toUpperCase())[0];
    return card.label;
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export const decimalCount = num => {
    const numStr = String(num);
    if (numStr.includes('.')) {
        return numStr.split('.')[1].length;
    };
    return 0;
}

export const stringUpperCase = str => {
    if (str === "") {
        return ""
    } else {
        return str.toUpperCase()
    }
}

export const getStatusMessageByStatusCode = statusCode => {
    if (statusCode) {
        const message = statusMessage[statusCode];
        return message?.message ?? "";
    }
    return "";
}
export const convertDecimalNumber = num => {
    return num ? new Decimal(num).toFixed(2) : "";
}

export const convertSourceData = source => {
    return source ? getTranslator(source) : "";
}
export const convertTxnStatusData = status => {
    return status ? getTranslator(status) : "";
}

export const convertTxnActionData = action => {
    return action ? getTranslator(action) : "";
}

export const convertCardType = type => {
    return supportCardTypeOptionForChannel.filter((data) => data.value.toLocaleUpperCase() === type)[0]?.label ?? ""
}
export const convertWalletType = type => {
    return supportDigitalWalletTypeOption.find((data) => data.value === type)?.label
}
export const convertMitType = type => {
    return mitTypes.find((data) => data.value === type)?.label
}
export const convertMitMode = type => {
    return mitModes.find((data) => data.value === type)?.label
}


export const loadingCircular = () => {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '600px', maxHeight: '800px' }}>
        <CircularProgress style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'blue', opacity: 0.7 }} />
    </div>
}


export const getTranslator = (id) => {
    if (id) {
        return (<FormattedMessage id={id} />)
    }
    return "";
}

export const upperFirstCase = (str) => {
    if(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return "";
}



export const getAVSMessage = (id, type) => {
    if (!id || !type) {
        return "";
    }
    if (id !== "NOAVS") {
        const data = type === "cybersource" ? cybsAVSCode.filter(({ value }) => value === id)[0] : worldpayAVSCode.filter(({ value }) => value === id)[0] ?? "";
        const label = data.label ?? "";
        return id + (label ? " , " + label : label)
    }
    return getTranslator(id);
}

export const getCVNMessage = (id, type) => {
    if (!id || !type) {
        return "";
    }
    const data = type === "cybersource" ? cybsCVNCode.filter(({ value }) => value === id)[0] : worldpayCVNCode.filter(({ value }) => value === id)[0];
    return id + " , " + data.label
}
