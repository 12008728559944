

import axios from "axios";

import { toast } from "react-toastify";
export const REFRESH_TOKEN_URL = `${process.env.REACT_APP_API_URL}/refresh`;



export const get = async (url, param, accessToken) => {
	try {
		let header = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User_Id: localStorage.getItem("userId") ?? "",
			}
		};
		const response = (await axios.get(url, param, header)).data;
		return response;
	} catch (err) {
		toast.error(err.message);
		return { error: true };
	}
}

export const post = async (url, param) => {
	try {
		let header = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
				User_Id: localStorage.getItem("userId") ?? "",
				User_Agent: window.navigator.userAgent ??"",
			}
		};
		const response = (await axios.post(url, param, header)).data;
		return response;
	} catch (err) {
		toast.error(err.message);
		return { error: true };
	}
}


export const checkRefreshToken = async() => {
	try{

		const refreshToken = localStorage.getItem("refreshToken");
		const header = {
			headers: {
				refresh_token: refreshToken,
			}
		};
		const res =await axios.post(REFRESH_TOKEN_URL, [], header);
		const data = res.data;
		if (data.code === 200) {
			localStorage.setItem("accessToken", data.results.accessToken);
			localStorage.setItem("refreshToken", data.results.refreshToken);
			return data.results.accessToken;
		} else {
			localStorage.removeItem("user");
			localStorage.removeItem("accessToken");
			localStorage.removeItem("refreshToken");
			localStorage.removeItem("userId");
			window.location.href = "/auth/login";
		}
		return res
	}	catch(err){
		localStorage.removeItem("user");
		localStorage.removeItem("accessToken");
		localStorage.removeItem("refreshToken");
		localStorage.removeItem("userId");
		window.location.href = "/auth/login";
}

};
