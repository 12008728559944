/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import ASideList from "./AsideList";
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    if (typeof url === "object") {
      url = url.path;
    }
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const generateList = () => {
    var menus = [];
    const userPermission = JSON.parse(localStorage.getItem("permission"));
    const list = ASideList(userPermission);
    Object.keys(list).map((item, index) => {
      const data = list[item];
      if (data.submenu === undefined) {
        const path = data.path;
        const name = data.name;
        const icon = data.icon;
        menus.push(
          <li
            key={index}
            className={`menu-item ${getMenuItemActive({ path }, false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={path}>
              <i className={icon} aria-hidden="true"></i>
              <div className="ml-2"><span className="menu-text">{name}</span></div>
            </NavLink>
          </li>
        );
      } else {
        var submenus = [];
        const icon = data.icon;
        const name = data.name;
        const submenu = data.submenu;
        Object.keys(submenu).map((menu, subindex) => {
          if (submenu[menu] !== undefined) {
            const subname = submenu[menu].name;
            const subpath = submenu[menu].path;
            submenus.push(
              <li
                key={subindex}
                className={`menu-item ${getMenuItemActive(subpath)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={subpath}>
                  <span className="menu-text">{subname}</span>
                </NavLink>
              </li>
            );
          }
        });
        menus.push(
          <li
            key={index}
            className={`menu-item menu-item-submenu  ${getMenuItemActive(
              "#",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              to="/#"
            >
              <span className="svg-icon menu-icon">
                <i className={icon} aria-hidden="true"></i>
              </span>
              <span className="menu-text">{name}</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">{submenus}</ul>
            </div>
          </li>
        );
      }
    });
    return menus;
  };
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>{generateList()}</ul>
    </>
  );
}
