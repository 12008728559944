import React from "react";
import * as yup from "yup";
import { getTranslator } from "./Common"

export const passwordSchema = yup.string()
    .min(8, getTranslator('MINIMUM_EIGHT_SYMBOLS'))
    .max(50, getTranslator('MAXIMUM_FIFTY_SYMBOLS'))
    .required(
        getTranslator('REQUIRED_FIELD')
    )

