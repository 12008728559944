export function getUserRole(role) {
  return roles[role];
}

const roles = {
  SUPER_ADMIN: "Super Admin",
  INTERNAL_ADMIN: "Internal Admin",
  EXTERNAL_ADMIN: "Admin",
  INTERNAL_USER: "Internal User",
  EXTERNAL_USER: "User",
};
